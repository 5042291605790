import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_EVENTS, DATA_TYPE_CONTACTS } from 'data/config/dataConfig';

import { DEFAULT_IMAGE } from 'data/config/ficheConfig';

import { CONTACT_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailSocialRaw from 'src/components/detail-social/DetailSocialRaw';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import PhoneRow from 'src/components/fiche/PhoneRow';
import EmailRow from 'src/components/fiche/EmailRow';

import SaveContactButton from 'src/components/save-contact-button/SaveContactButton';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import '../common-fiche.scss';
import './ContactPage.scss';

class ContactContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_CONTACTS,
      this.props.isFavorite
    );
  };

  showEvents = () => {
    this.props.actions.navigateToItems(
      this.props.item.references[DATA_TYPE_EVENTS],
      DATA_TYPE_EVENTS,
      this.props.item.id,
      DATA_TYPE_CONTACTS
    );
  };

  getTitle = (item) =>
    (item.civility ? item.civility + ' ' : '') +
    (item.firstName ? item.firstName + ' ' : '') +
    (item.lastName ? item.lastName : '');

  render() {
    const item = this.props.item,
      title = this.getTitle(item);

    let imageUrl =
      item.profilePicture && typeof item.profilePicture.value === 'string'
        ? item.profilePicture.value
        : null;
    if (!imageUrl && DEFAULT_IMAGE) {
      imageUrl = DEFAULT_IMAGE[DATA_TYPE_CONTACTS];
    }

    return (
      <div className="fiche contact content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          dataType={DATA_TYPE_CONTACTS}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <SaveContactButton
            item={this.props.item}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={CONTACT_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <div className="fiche-row1">
              <div className="fiche-row1-col1">
                {imageUrl && <img className="fiche-photo" src={getUrl('/' + imageUrl)} alt="" />}
              </div>
              <div className="fiche-row1-col2">
                <div className="fiche-perso">
                  {item.role && <div>{item.role}</div>}
                  <DetailSocialRaw data={item.social} actions={this.props.actions} />
                </div>
                <div className="fiche-entreprise">
                  {item.organizationName && <div className="">{item.organizationName}</div>}
                  {item.organizationLogo && (
                    <div className="fiche-logo">
                      <img src={getUrl('/' + item.organizationLogo)} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="fiche-row2">
              <WebsiteRow website={item.organizationWebsite} actions={this.props.actions} />
              {item.phone && <PhoneRow phone={item.phone} />}
              {item.mobilePhone && <PhoneRow phone={item.mobilePhone} />}
              {item.email && <EmailRow email={item.email} />}
              {item.address && (
                <div className="free-row">
                  <div className="prop-img">
                    <div className="prop-left">
                      <div className="fiche-contact-icon">
                         <GenericIcon
                            src={'svg/fiche/university-solid.svg'}
                            className="university-icon"
                          />
                      </div>
                    </div>
                    <div
                      className="prop-right"
                      dangerouslySetInnerHTML={{
                        __html: item.address,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactContent.propTypes = {
  item: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isFavorite: PropTypes.bool,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ContactContent;
