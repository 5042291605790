import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import config from 'data/config/config';

import { CATEGORIES_DATA_TYPE, CATEGORIES_MAPPING } from 'data/config/dataConfig';

import { SEARCH_MIN_CHARS } from 'data/config/searchConfig';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import {
  FAVORITES_PAGE_KEY,
  LIST_PAGE_KEY,
  LIST_GROUPS_PAGE_KEY,
  SEARCH_PAGE_KEY,
  SYNOPTIC_AGENDA_PAGE_KEY,
} from 'src/pages/pagesKeys';

import SearchField from 'src/components/search/SearchField';

import './AppToolbar.scss';

export const APP_TOOLBAR_HEIGHT = 48;

const getButton = (icon, iconStyle, action, label, src, props) => {
  return (
    <span className="app-toolbar-icon" onClick={action} title={label}>
      <GenericIcon src={src} className={icon} {...props} style={iconStyle} />
    </span>
  );
};

class AppToolbar extends PureComponent {
  setSearchFieldRef = (ref) => {
    this.searchField = ref;
  };

  hasRootCategoryButtonInToolbar = () =>
    !this.props.searchFieldVisible && this.props.rootCategoryButtonInToolbar;

  hasSynchroFavBtnStatus = () =>
    this.props.isSynchroFavFeatureEnabled &&
    !this.props.searchFieldVisible &&
    this.props.synchroFavBtnStatus;

  // BACK

  hasBackButton = () => this.props.hasBackButton;

  handleClickOnBackButton = () => {
    if (this.props.searchFieldVisible) {
      this.clearSearchResults();
      this.hideSearchField();
    } else {
      this.props.actions.navigateBack();
    }
  };

  // HOME

  hasHomeButton = () => !this.props.searchFieldVisible && this.props.hasHomeButton;

  navigateToHome = () => {
    let homePage = config.getHomePage(this.props.profile);
    this.props.actions.navigate(homePage.pageKey, homePage.props);
  };

  // FAVORITES

  hasFavButton = () => !this.props.searchFieldVisible && this.props.hasFavButton === true;

  navigateToFavoritesPage = () => {
    this.props.actions.navigate(FAVORITES_PAGE_KEY);
  };

  // SYNOPTIC

  hasSynopticAgendaButton = () =>
    !this.props.searchFieldVisible && this.props.hasSynopticAgendaButton;

  navigateToSynopticAgendaPage = () => {
    this.props.actions.navigateToSynopticWithoutContext();
  };

  // AGENDA

  hasBackToAgendaButton = () => !this.props.searchFieldVisible && this.props.hasBackToAgendaButton;

  navigateBackToAgendaPage = () => {
    this.props.actions.navigate(LIST_GROUPS_PAGE_KEY, this.props.agendaProps);
  };

  // MENU

  hasMenuButton = () => this.props.hasMenuButton;

  openMenu = () => {
    // this.hideSearchField();
    this.props.actions.openMenu(this.props.pageKey);
  };

  // FILTER

  hasFilterButton = () => !this.props.searchFieldVisible && this.props.hasFilterButton;

  handleFilterButtonClick = () => {
    this.props.actions.showFilterDialog(this.props.contextualDataTypes);
  };

  // SEARCH
  hasSearchButton = () =>
    this.props.pageKey !== SEARCH_PAGE_KEY &&
    !this.props.searchFieldVisible &&
    this.props.hasSearchButton === true;

  handleSearchButtonClick = () => {
    if (this.props.contextualDataTypes) {
      this.showSearchField();
      this.focusOnSearchField();
    } else {
      this.props.actions.navigate(SEARCH_PAGE_KEY);
    }
  };

  showSearchField = () => {
    this.props.actions.setSearchFieldVisible(this.props.pageKey, true);
  };

  focusOnSearchField = () => {
    // NB: on iOS, the focus can only be set following a user action (no timeout possible)
    if (this.searchField) {
      this.searchField.focus();
    }
  };

  getSearchFieldPlaceholder({ contextualDataTypes, labels }) {
    const placeholder = [];
    if (Array.isArray(contextualDataTypes)) {
      contextualDataTypes.forEach((dataType) => {
        let type = dataType;

        if (CATEGORIES_DATA_TYPE.indexOf(dataType) > -1) {
          type = CATEGORIES_MAPPING[dataType];
        }

        const text = labels.data[type].plural.toLowerCase();

        if (placeholder.indexOf(text) === -1) {
          placeholder.push(text);
        }
      });
    }

    if (labels.search.keywords) {
      placeholder.push(labels.search.keywords);
    }

    return placeholder.join(', ');
  }

  hideSearchField = () => {
    this.props.actions.setSearchFieldVisible(this.props.pageKey, false);
  };

  clearSearchResults = () => {
    this.props.actions.clearSearchResults(this.props.pageKey);
  };

  performSearch = (str) => {
    this.props.actions.performSearch(str, this.props.pageKey, this.props.contextualDataTypes, true);
  };

  getClassName = () =>
    'app-toolbar' +
    (this.props.theme ? ' theme-' + this.props.theme : '') +
    (!this.props.disableDropShadow ? ' at-with-dropshadow' : '');

  render() {
    if (this.props.isDisplayed)
      return (
        <div className={this.getClassName()} ref={this.setToolbarEl}>
          <div className="left">
            {this.hasBackButton() &&
              getButton(
                'arrow_back back-icon',
                null,
                this.handleClickOnBackButton,
                this.props.labels.common.goBack,
                'svg/appToolBar/fleche.svg'
              )}
          </div>

          <CSSTransition in={this.props.searchFieldVisible} classNames="search-field" timeout={0}>
            <SearchField
              key={0}
              ref={this.setSearchFieldRef}
              placeholder={this.getSearchFieldPlaceholder(this.props)}
              perform={this.performSearch}
              clearResults={this.clearSearchResults}
              searchResults={this.props.searchResults}
              minChars={this.props.pageKey === LIST_PAGE_KEY ? 1 : SEARCH_MIN_CHARS}
              isContextual={true}
              hasCancelButton={true}
              onCancel={this.hideSearchField}
            />
          </CSSTransition>

          {!this.props.searchFieldVisible && (
            <div className="center content-font">
              {this.props.contextualTitle || this.props.title}
            </div>
          )}

          <div className="right">
            {this.props.listToolBarButton &&
              this.props.listToolBarButton.length !== 0 &&
              this.props.listToolBarButton.map((button) =>
                getButton(
                  button.className + ' ' + button.isFontAwesome || '',
                  button.style,
                  button.action,
                  button.label,
                  button.src
                )
              )}
            {this.hasSynopticAgendaButton() &&
              getButton(
                'calendar-icon',
                { fontSize: '.95em' },
                this.navigateToSynopticAgendaPage,
                this.props.labels.common.goToSynopticAgenda,
                'svg/appToolBar/calendar-alt-regular.svg'
              )}

            {this.hasBackToAgendaButton() &&
              getButton(
                'calendar-week-icon',
                { fontSize: '.95em' },
                this.navigateBackToAgendaPage,
                this.props.labels.common.goBackToAgenda,
                'svg/appToolBar/calendar-week-solid.svg'
              )}

            {this.hasRootCategoryButtonInToolbar() &&
              getButton(
                'list-icon',
                { fontSize: '22px' },
                this.props.navigateToRootCategory,
                this.props.labels.common.goToRootCategory,
                'svg/appToolBar/list-solid.svg'
              )}

            {this.hasHomeButton() &&
              getButton(
                'home-icon',
                { width: '0.8em', height: '0.8em' },
                this.navigateToHome,
                this.props.labels.common.goToHome,
                'svg/Home.svg'
              )}

            {this.hasFavButton() === true &&
              getButton(
                'full-star',
                { width: '0.8em', height: '0.8em' },
                this.navigateToFavoritesPage,
                this.props.labels.common.goToFavorites,
                'svg/control/full-star.svg',
                { st0Class: 'app-toolbar-icon-st0' }
              )}

            {this.hasSearchButton() === true &&
              getButton(
                'search-icon',
                { width: '0.8em', height: '0.8em' },
                this.handleSearchButtonClick,
                this.props.labels.common.goToSearch,
                'svg/appToolBar/magnifingGlass.svg',
                { st0Class: 'app-toolbar-icon-st0' }
              )}

            {this.hasFilterButton() &&
              getButton(
                'filter-icon' + (this.props.isDataFiltered ? ' filter-enabled' : ''),
                { width: '0.8em', height: '0.8em' },
                this.handleFilterButtonClick,
                this.props.labels.common.goToFilter,
                'svg/appToolBar/filter-solid.svg'
              )}

            {this.hasSynchroFavBtnStatus() &&
              getButton(
                `sync-icon synchro-fav-btn synchro-fav-btn-${this.props.synchroFavBtnStatus}`,
                { width: '0.8em', height: '0.8em', margin: '0 0.1em', padding: '0 0.1em' },
                this.props.actions.synchroFavoritesIconClicked,
                '',
                'svg/klipso/sync-solid.svg'
              )}

            {this.hasMenuButton() === true &&
              getButton(
                'burger-icon',
                { width: '0.8em', height: '0.8em' },
                this.openMenu,
                this.props.labels.common.openMenu,
                'svg/appToolBar/Burger.svg'
              )}
          </div>
        </div>
      );
    return null;
  }
}

AppToolbar.propTypes = {
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  profile: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool,
  title: PropTypes.string.isRequired,
  contextualTitle: PropTypes.string,
  pageKey: PropTypes.string,
  hasBackButton: PropTypes.bool,
  hasMenuButton: PropTypes.bool,
  hasHomeButton: PropTypes.bool,
  hasFavButton: PropTypes.bool,
  hasSearchButton: PropTypes.bool,
  hasFilterButton: PropTypes.bool,
  hasSynopticAgendaButton: PropTypes.bool,
  agendaProps: PropTypes.object,
  hasBackToAgendaButton: PropTypes.bool,
  onSearchClear: PropTypes.func,
  synchroFavBtnStatus: PropTypes.string,
  rootCategoryButtonInToolbar: PropTypes.bool,
  navigateToRootCategory: PropTypes.func,
  searchResults: PropTypes.object,
  lastResize: PropTypes.number,
  isDataFiltered: PropTypes.bool,
  disableDropShadow: PropTypes.bool,
  searchFieldVisible: PropTypes.bool,
  theme: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => state['appToolbar'];

export default connect(mapStateToProps)(AppToolbar);
