import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

const PhoneRow = (props) =>
  !props.phone ? null : (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <GenericIcon
            src={'svg/fiche/telephone.svg'}
            st1Class="row-st1"
            st0Class="row-st0"
            className="phone-row"
          />
        </div>
        <div
          className="prop-right"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <a className="link colored-link" href={`tel:${props.phone}`}>
            {props.phone}
          </a>
        </div>
      </div>
    </div>
  );

PhoneRow.propTypes = {
  phone: PropTypes.string,
};

export default PhoneRow;
